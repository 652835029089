import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConnectCoreModule, ConnectCoreModuleConfig, AUTHENTICATION_CONFIG, COMPONENTS_CONFIG, CONNECT_API_CONFIG } from '@aveva/connect-web-core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenInterceptor } from './token.interceptor';

import { CONFIGURATION, Configuration } from 'src/configuration';
import { ConnectAdminGuard } from './connect-admin.guard';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceEditComponent } from './maintenance/maintenance-edit.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MaintenanceComponent,
    MaintenanceEditComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ConnectCoreModule.forRoot({} as ConnectCoreModuleConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    CommonModule,
    ConnectCoreModule,
    FormsModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatCardModule,
    MatTableModule,
    CdkTableModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule
  ],
  providers: [
    {
      provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: true
      }
    },
    {
      provide: AUTHENTICATION_CONFIG,
      useFactory: (configuration: Configuration) => ({
        authority: configuration.authority,
        clientId: configuration.clientId,
        cachePrefix: 'AVEVA_CloudUE_Management'
      }),
      deps: [CONFIGURATION],
    },
    {
      provide: COMPONENTS_CONFIG,
      useFactory: () => ({
        appHeaderTitle: 'Unified Engineering Management',
        appHeaderLogoPath: '', // Path to a custom logo for your capability can be specified here.
        sideNavLogoPath: '/assets/imgs/AVEVA-Cloud_interimmark_rev01-01_black.svg'
      }),
    },
    {
      provide: CONNECT_API_CONFIG,
      useFactory: (configuration: Configuration) => ({
        logging: configuration.apis.logging,
        am: configuration.apis.am,
        scm: configuration.apis.scm,
        uam: configuration.apis.uam,
      }),
      deps: [CONFIGURATION],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ConnectAdminGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
