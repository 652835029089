import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedUserResolverService, UserProfileComponent, UserProfileResolverService } from '@aveva/connect-web-core';
import { ConnectAdminGuard } from './connect-admin.guard';
import { MaintenanceEditComponent } from './maintenance/maintenance-edit.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];

const childrenRoutes: Routes = [
  {
    path: 'maintenance',
    canActivate: [ConnectAdminGuard],
    resolve: {
      user: AuthenticatedUserResolverService
    },
    children: [
      {
        path : '',
        component: MaintenanceComponent
      },
      {
        path : ':solutionid/edit',
        component: MaintenanceEditComponent
      }
    ]
  },
  {
    path: 'me',
    component: UserProfileComponent,
    resolve: {
      user: UserProfileResolverService
    },
    data: {
      redirectRoute: ['/']
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
    useHash: true,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
}
    ),
    RouterModule.forChild(childrenRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class AppRoutingModule { }
